import { createApp, defineAsyncComponent } from 'vue'

import App from './App.vue'
import VueTelInput from 'vue-tel-input';
import router from './router'
import 'vue-tel-input/vue-tel-input.css';
import store from './store'
import mitt from 'mitt'
import * as Sentry from '@sentry/vue';

// imports dynamiques
const VueFeather = defineAsyncComponent(() => import(/* webpackChunkName: "vue-feather" */ 'vue-feather'))
const Particles = defineAsyncComponent(() => import(/* webpackChunkName: "particlesjs" */ 'vue3-particles').then((particles) => particles.ParticlesComponent))
const emitter = mitt()

const app = createApp(App)

app.use(VueTelInput)
Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTarget: process.env.VUE_APP_API_URL,
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.VUE_APP_ENV,
});

app
  .use(store)
  .use(router)
  .use(VueTelInput, { mode: 'auto', })
  .component('Particles', Particles)
  .component('vue-feather', VueFeather)
app.config.globalProperties.emitter = emitter
app.mount('#app')
