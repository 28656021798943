import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'

// import statiques des vues qui seront chargés avec le site
import Login from '@/views/Login.vue'
import Signup from '@/views/Signup.vue'
import BoxChoice from '@/views/BoxChoice.vue'
import ResetForm from '@/views/ResetForm.vue'
import ResetRequest from '@/views/ResetRequest.vue'
import WaitingVerif from '@/views/WaitingVerif'
import MailVerification from '@/views/MailVerification.vue'

// import dynamique des vues chargés seulement quand demandées

const Intro = () => import(/* webpackChunkName: "intro" */ '@/views/Intro.vue')
const Reinforcment = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashBoard/Reinforcment.vue')
const Document = () => import(/* webpackChunkName: "inbox" */ '@/views/dashBoard/inbox/Document.vue')
const Help = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashBoard/Help.vue')
const BoxEnd = () => import(/* webpackChunkName: "box-end" */ '@/views/BoxEnd.vue')
const Confession = () => import(/* webpackChunkName: "end" */ '@/views/Confession.vue')
const Detective = () => import(/* webpackChunkName: "detective" */ '@/views/Detective.vue')
const Suspects = () => import(/* webpackChunkName: "suspects" */ '@/views/dashBoard/Suspects.vue')
const DashBoard = () => import(/* webpackChunkName: "dashboard" */ '@/views/DashBoard.vue')
const Home = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashBoard/Home.vue')
const Archive = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashBoard/Archive.vue')
const Inbox = () => import(/* webpackChunkName: "inbox" */ '@/views/dashBoard/Inbox.vue')
const Investigation = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashBoard/Investigation.vue')
const Search = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashBoard/Search.vue')
const Objective = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashBoard/Objective.vue')
const BoxInbox = () => import(/* webpackChunkName: "inbox" */ '@/views/dashBoard/inbox/BoxInbox.vue')

const routes = [
  {
    path: '/choix-box',
    name: 'BoxChoice',
    component: BoxChoice
  },
  {
    path: '/connexion',
    name: 'Login',
    component: Login
  },
  {
    path: '/inscription',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/attente-verification',
    name: 'WaitingVerif',
    component: WaitingVerif
  },
  {
    path: '/validation-e-mail/:token',
    name: 'MailVerif',
    component: MailVerification,
    props: true
  },
  {
    path: '/reinitialisation-mot-de-passe',
    name: 'ResetRequest',
    component: ResetRequest
  },
  {
    path: '/reinitialisation-mot-de-passe/:authId/:token',
    name: 'ResetForm',
    component: ResetForm,
    props: true
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro
  },
  {
    path: '/mon-compte',
    name: 'Detective',
    component: Detective
  },
  {
    path: '/tableau-de-bord-base',
    name: 'DashBoard',
    component: DashBoard,
    children: [
      {
        path: '/tableau-de-bord',
        name: 'Home',
        component: Home
      },
      {
        path: '/aide',
        name: 'Help',
        component: Help
      },
      {
        path: '/archives-de-police',
        name: 'Archive',
        component: Archive
      },
      {
        path: '/boite-de-reception',
        name: 'Inbox',
        component: Inbox,
        children: [
          {
            path: 'document/',
            name: 'Document',
            component: Document,
          },
          {
            path: '/boite-de-reception/:box',
            name: 'BoxInbox',
            component: BoxInbox,
            props: true
          }
        ]
      },
      {
        path: '/interrogatoire',
        name: 'Investigation',
        component: Investigation
      },
      {
        path: '/objectif/:id',
        name: 'Objective',
        component: Objective,
        props: true
      },
      {
        path: '/renfort',
        name: 'Reinforcment',
        component: Reinforcment
      },
      {
        path: '/fouille',
        name: 'Search',
        component: Search
      },
      {
        path: '/suspects',
        name: 'Suspects',
        component: Suspects
      }
    ]
  },
  {
    path: '/aveux',
    name: 'Confession',
    component: Confession
  },
  {
    path: '/fin-de-la-box/:id',
    name: 'BoxEnd',
    component: BoxEnd,
    props: true
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// handling loading bar
router.beforeEach((to, from, next) => {
  NProgress.start()
  NProgress.set(0.1)
  next()
})
router.afterEach(() => {
  NProgress.done()
})

// gestion de l'acces aux pages selon la connection ou non du joueur
router.beforeEach((to, from, next) => {
  if ((from.name === undefined && to.name !== 'MailVerif' && to.name !== 'ResetRequest' && to.name !== 'ResetForm' && to.path.split('/')[1] !== 'assets')) {
    from.name = 'loading'
    store.dispatch('fetchBoxList')
    store.dispatch('loadStoredData').then(() => {
      return store.dispatch('checkLogin').then(res => {
        if (res.status === 200) {
          return store.dispatch('fetchDetective').then(() => next({ name: 'BoxChoice' }))
        }
      }).catch(() => {
        next({ name: 'Login' })
      })
    })
    // Si la page demandé demande d'être connecté
  } else if (to.name === 'BoxChoice' || to.name === 'Home' || to.name === 'WaitingVerif') {
    // on vérifie si le joueur est bien connecté
    store.dispatch('checkLogin')
      .then(() => {
        // si oui on vérifie si son adresse mail est vérifié
        if (store.state.detective.checkedMail || to.name === 'WaitingVerif') {
          // si oui on laisse le joueur accéder à la page qu'il voulait
          next()
        } else {
          // sinon on le renvoit sur la page lui demandant de suivre
          // le lien qui lui à été envoyé par mail
          next({ name: 'WaitingVerif' })
        }
      })
      // s'il n'est pas connecté, on vide les données de connexion et on l'envoi vers la page de connexion
      .catch(() => {
        store.dispatch('disconnect')
        return next({ name: 'Login' })
      })
  } else if (to.path.split('/')[1] === 'assets') {
    next()
  } else {
    // si la page ne nécéssite pas d'être connecté on vérifie quand même
    if (store.state.detective.connected) {
      // s'il est connecté et qu'il demande d'aller sur une des pages
      // correspondant à la vérification de l'adresse mail
      if (to.name === 'MailVerif') {
        // on vérifie si son adresse mail est vérifié
        if (store.state.detective.checkedMail) {
          // si oui, on le regirige vers le jeu
          next({ name: 'BoxChoice' })
        } else {
          // sinon on laisse le joueur accéder à la page qu'il voulait
          next()
        }
      } else if (to.name === 'Login' || to.name === 'Signup'){
        // s'il demande la page de login ou d'inscription,
        // on le renvois vers le tableau de bord
        next({ name: 'BoxChoice' })
      } else {
        next()
      }
    } else {
      // s'il n'est pas connecté, on laisse le joueur accéder à la page qu'il voulait
      next()
    }
  }
})

export default router
