import * as Sentry from '@sentry/vue';
import ApiServices from '@/services/ApiServices'
import localForage from 'localforage'

localForage.config({
  storeName: 'detectiveBox'
})

const errorNotif = (dispatch, message) => {
  return dispatch('setNotif', message, { root: true })
}

export default {
  // donnée stockés concernant le joueur
  state: {
    connected: false,
    jwt: null, // token de connexion
    detectiveId: null,
    name: '',
    mail: '',
    checkedMail: false,
    inbox: [],
    gameSave: []
  },
  mutations: {
    // stocke le token de connexion dans le store et
    // dans un strockage permanent chez le client et paramettres axios avec
    SET_JWT(state, jwt) {
      state.jwt = jwt
      localForage.setItem('jwt', jwt)
      ApiServices.setAuthorization(jwt)
    },
    // stocke l'id du compte joueur dans le store et dans un strockage permanent chez le client
    SET_DETECTIVE_ID(state, id) {
      state.detectiveId = id
      localForage.setItem('detectiveId', id)
    },
    // stocke les informations du joueur dans le store
    SET_DETECTIVE(state, detective) {

      state.name = detective.name
      state.mail = detective.email
      state.checkedMail = detective.checkedMail
      let inb = []
      for (const box of Object.entries(detective.progress.app1.inbox)) {
        inb.push([box[0], box[1]])
      }
      state.inbox = inb

      let gameSaves = []
      for (const box of Object.entries(detective.progress.app1.gameSave)) {
        let gs = box[1]
        const va = []
        for (const objective of Object.entries(gs.validatedObjectives)) {
          va.push([objective[0], objective[1]])
        }
        gs.validatedObjectives = va
        gameSaves.push([box[0], gs])
      }
      state.gameSave = gameSaves
      state.connected = true
    },
    // retire les informations du joueur du store
    DISCONNECT(state) {
      state.detectiveId = null
      state.name = ''
      state.mail = ''
      state.checkedMail = false
      state.connected = false
    }
  },
  actions: {
    signup({ dispatch }, { password, detective }) {
      return ApiServices.signup(password, detective)
        .then((res) => {
          if (res.status === 201) {
            dispatch('setNotif', res.data.message, { root: true })
          } else {
            dispatch('setNotif', res.data.message, { root: true })
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    login({ commit, dispatch }, { password, mail }) {
      return ApiServices.login(password, mail)
        .then((res) => {
          if (res.status === 200) {
            commit('SET_JWT', res.data.accessToken)
            commit('SET_DETECTIVE_ID', res.data.userId)
          }
        })
        .catch((err) => {
          console.error
          errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message))
          throw err
        })
    },
    disconnect({ commit, dispatch }, isNotified = true) {
      commit('SET_JWT', null)
      commit('SET_DETECTIVE_ID', null)
      commit('DISCONNECT')
      return isNotified ? dispatch('setNotif', 'Vous êtes bien déconnecté.', { root: true }) : true
    },
    fetchDetective({ commit, dispatch, state }) {
      return ApiServices.getDetective(state.detectiveId)
        .then((res) => {
          if (res.status === 200) {
            commit('SET_DETECTIVE', res.data)
          } else {
            throw res.data.error
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    editDetective({ dispatch, state }, form) {
      return ApiServices.editDetecive(state.detectiveId, form)
        .then((res) => {
          if (res.status === 201) {
            dispatch('fetchDetective')
          } else {
            throw res.data.error
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    editAuth({ dispatch, state }, form) {
      return ApiServices.editAuth(state.detectiveId, form)
        .then((res) => {
          if (res.status !== 201) {
            throw res.data.error
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    deleteDetective({ dispatch, state }) {
      return ApiServices.deleteDetective(state.detectiveId)
        .then(res => {
          if (res.status === 200) {
            dispatch('disconnect')
            dispatch(
              'setNotif',
              res.data.message,
              { root: true }
            )
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    resendMail({ dispatch, state }) {
      return ApiServices.resendMail(state.detectiveId)
        .then(res => {
          if (res.status === 200) {
            dispatch('fetchDetective')
            dispatch(
              'setNotif',
              res.data.message,
              { root: true }
            )
          } else {
            errorNotif(dispatch, res.data.message)
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    validateMail({ dispatch }, token) {
      return ApiServices.validateMail(token)
        .then(res => {
          if (res.status === 201) {
            dispatch(
              'setNotif',
              res.data.message,
              { root: true }
            )
          } else {
            errorNotif(dispatch, res.data.message)
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    async sendResetMail({ dispatch }, mail) {
      const res = await ApiServices.sendResetMail(mail)
        .catch((err) => errorNotif(dispatch, (err.response ? err.response.data.message : err.message)))
      if (res.status === 201) {
        dispatch(
          'setNotif',
          res.data.message,
          { root: true }
        )
      } else {
        errorNotif(dispatch, res.data.message)
      }
    },
    async reset({ dispatch }, { token, authId, password }) {
      const res = await ApiServices.reset(token, authId, password)
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
      if (res.status === 201) {
        dispatch(
          'setNotif',
          res.data.message,
          { root: true }
        )
      }
    },
    async inboxAddDocument({ dispatch, state }, { box, document }) {
      let alreadyInInbox = false
      state.inbox.forEach((inbox) => {
        if (inbox !== undefined) {
          if (inbox[1].filter((_document) => _document.documentId === document.documentId).length > 0) {
            alreadyInInbox = true
            // cas spécial pour que les joueurs puissent retrouver le document "archive de fraude - 2012" en box 2
            // même s'ils l'ont dans leur boite de réception de box 1
            if (box === 2 && document.documentId === "609eeecef873041f6173a93d" && inbox[0] === "1") {
              alreadyInInbox = false
            }
          }
        }
      })
      if (!alreadyInInbox) {
        const res = await ApiServices.inboxAddDocument(state.detectiveId, box, document)
          .catch((err) => {
            return errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message))
          })
        if (res.status === 201) {
          await dispatch('fetchDetective')
          return true
        } else {
          errorNotif(dispatch, res.data.message)
          return false
        }
      } else {
        return false
      }
    },
    async inboxDocumentSeen({ dispatch, state }, { box, documentId }) {
      const res = await ApiServices.inboxDocumentSeen(state.detectiveId, box, documentId)
        .catch((err) => {
          return errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message))
        })
      return res.status === 201 ? await dispatch('fetchDetective') : errorNotif(dispatch, res.data.message)

    },
    viewIntro({ dispatch, state }, box) {
      return ApiServices.viewIntro(state.detectiveId, box)
        .then(res => {
          if (res.status === 201) {
            dispatch('fetchDetective')
          } else {
            errorNotif(dispatch, res.data.message)
          }
        })
        .catch((err) => {
          return errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message))
        })
    },
    addSuspect({ dispatch, state }, { box, suspect }) {
      let suspects = state.gameSave[box - 1][1].suspects
      if (suspects.length < 7) {
        suspects = [...suspects, ...suspect].sort()
      }
      return ApiServices.updateSuspects(state.detectiveId, box, suspects)
        .then(res => {
          if (res.status === 201) {
            dispatch('fetchDetective')
          } else {
            errorNotif(dispatch, res.data.message)
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    removeSuspect({ dispatch, state }, { box, suspect }) {
      const id = state.gameSave[box - 1][1].suspects.indexOf(suspect)
      const suspects = state.gameSave[box - 1][1].suspects
      suspects.splice(id, 1)
      return ApiServices.updateSuspects(state.detectiveId, box, suspects)
        .then(res => {
          if (res.status === 201) {
            dispatch('fetchDetective')
          } else {
            errorNotif(dispatch, res.data.message)
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    async validateObjective({ dispatch, state }, { box, objective, answers }) {
      const res = await ApiServices.validateObjective(state.detectiveId, box, objective, answers)
        .catch((err) => {
          return errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message))
        })
      if (res) {
        if (res?.status === 200) {
          if (res?.data?.valid) {
            await dispatch('fetchDetective')
          }
        }
        return res
      }


    },
    validateBox({ dispatch, state }, { box, suspects }) {
      return ApiServices.validateBox(box, suspects)
        .then(res => {
          if (res.status === 200) {
            if (res.data.valid) {
              dispatch('fetchDetective')
            }
            return { valid: res.data.valid, message: res.data.message }
          } else {
            return { valid: null, message: res.data.message }
          }
        })
        .catch((err) => errorNotif(dispatch, "Erreur de connexion au serveur: " + "\n" + (err.response ? err.response.data.message : err.message)))
    },
    async getScore({ dispatch, state }) {
      try {
        const res = await ApiServices.getScore(state.detectiveId)
        if (res.status === 200) {
          return res.data.score
        } else {
          errorNotif(dispatch, res.data.message)
        }
      } catch (err) {
        errorNotif(dispatch, (err.response ? err.response.data.message : err.message))
      }
    },
    checkLogin({ state }) {
      Sentry.setUser({ id: state.detectiveId });
      return ApiServices.checkLogin(state.detectiveId)
    },
    loadStoredData({ commit }) {
      return localForage.getItem('jwt').then((jwt) =>
        localForage.getItem('detectiveId').then((detectiveId) => {
          commit('SET_JWT', jwt)
          commit('SET_DETECTIVE_ID', detectiveId)
        })
      )
    }
  }
}
