import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 100000,
})

export default {
  signup(password, detective) {
    return apiClient.post('/users', {
      password: password,
      email: detective.mail,
      name: detective.name,
      phoneNumber: detective.phoneNumber,
      ageMean: detective.ageMean,
      detectiveCount: detective.nbPlayer
    })
  },
  login(password, mail) {
    return apiClient.post('auth/login', {
      password: password,
      email: mail
    })
  },
  getDetective(detectiveId) {
    return apiClient.get("users/me")
  },
  editDetecive(detectiveId, form) {
    return apiClient.put('users/me', {
      name: form.name,
      email: form.mail,
    })
  },
  editAuth(detectiveId, form) {
    return apiClient.put('users/password', {
      password: form.password
    })
  },
  deleteDetective(detectiveId) {
    return apiClient.put('app1/detective/reset')
  },
  resendMail(detectiveId) {
    return apiClient.post('app1/detective/mail/resend', { detectiveId: detectiveId })
  },
  validateMail(token) {
    return apiClient.get('app1/detective/mail/validate/' + token)
  },
  sendResetMail(mail) {
    return apiClient.post('users/forgot_password', { email: mail })
  },
  reset(token, authId, password) {
    return apiClient.put('users/password', { password: password }, { headers: { 'Authorization': 'Bearer ' + token } })
  },
  inboxAddDocument(detectiveId, box, document) {
    return apiClient.post(`app1/detective/inbox/${box}/documents`, {
      document
    })
  },
  inboxDocumentSeen(detectiveId, box, documentId) {
    return apiClient.post(`app1/detective/inbox/${box}/documents/${documentId}/seen`)
  },
  viewIntro(detectiveId, box) {
    return apiClient.post(`app1/detective/gamesave/box/${box}/intro`)
  },
  updateSuspects(detectiveId, box, suspects) {
    return apiClient.post(`app1/detective/gamesave/box/${box}/suspects/update`, {
      suspects: suspects
    })
  },
  validateObjective(detectiveId, box, objective, answers) {
    return apiClient.post(`app1/detective/gamesave/box/${box}/objective/${objective}/validate`, {
      answers: answers
    })
  },
  validateBox(box, suspects) {
    let res =
      apiClient.post(`app1/detective/gamesave/box/${box}/validate`, {
        suspects: suspects
      })

    return res
  },
  getScore(detectiveId) {
    return apiClient.post('detective/score', { detectiveId: detectiveId })
  },
  checkLogin() {
    return apiClient.get('app1/detective/checklogin')
  },
  getBoxContent(box) {
    return apiClient.get(`app1/boxes/${box}`)
  },
  getBoxList() {
    return apiClient.get('app1/boxes')
  },

  setAuthorization(jwt) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${jwt}`
  }
}
